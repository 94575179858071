/* src/Work.css */
.work-section {
  padding: 20px;                /* Add more padding for a balanced layout */
  font-size: 16px;              /* Slightly larger font for better readability */
  text-align: left;
  max-height: 100%;
  overflow-y: auto;
}

.work-item {
  display: block;               /* Change to block layout to stack items vertically */
  margin-bottom: 40px;          /* Increase spacing between work items */
}

.work-info {
  padding-bottom: 20px;         /* Add padding below text content for separation from the images */
  max-width: 600px;             /* Limit the maximum width for better balance */
  margin: 0 auto;               /* Center the content */
}

.work-info p {
  margin-bottom: 10px;
}

.divider {
  border: 0;
  height: 1px;
  background-color: #00FF00;    /* Use your desired color */
  margin: 10px 0;               /* Spacing above and below the divider */
}

.work-info ul {
  padding-left: 20px;
  margin: 15px 0;
}

.work-info li {
  margin-bottom: 15px;
}

.image-box {
  width: 150px;                 /* Set fixed width for images */
  max-height: 220px;
  overflow: hidden;
  display: block;               /* Change to block display */
  margin: 0 auto 20px auto;     /* Center the image and add bottom margin */
  border: 1px solid #00FF00;
}

.image-box img, 
.image-box video {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.work-link {
  color: #00FF00;                /* Green color for the link */
  text-decoration: underline;    /* Underline to indicate it's a link */
  font-weight: bold;             /* Make the link bold */
  position: relative;
  display: inline-flex;
  align-items: center;
}

.work-link:hover {
  color: #FFFF00;                /* Change color on hover */
}

.link-icon {
  margin-left: 5px;              /* Space between the text and the icon */
  font-size: 1.2rem;             /* Slightly larger icon */
  transition: transform 0.2s ease-in-out;
}

/* Rotate the icon slightly on hover */
.work-link:hover .link-icon {
  transform: rotate(20deg);
}