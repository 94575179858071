/* src/projects.css */
.projects-section {
  padding: 20px;                /* Add more padding for a balanced layout */
  font-size: 16px;              /* Slightly larger font for better readability */
  text-align: left;
  max-height: 100%;
  overflow-y: auto;
}

.project {
  display: block;               /* Change to block layout to stack items vertically */
  margin-bottom: 40px;          /* Increase spacing between projects */
}

.project-info {
  padding-bottom: 20px;         /* Add padding below text content for separation from the images */
  max-width: 600px;             /* Limit the maximum width for better balance */
  margin: 0 auto;               /* Center the content */
}

.project-info p {
  margin-bottom: 10px;
}

.project-info ul {
  padding-left: 20px;
  margin: 15px 0;
}

.project-info li {
  margin-bottom: 15px;
}

.image-box {
  width: 200px;                 /* Set fixed width for images */
  max-height: 220px;
  overflow: hidden;
  display: block;               /* Change to block display */
  margin: 0 auto 20px auto;     /* Center the image and add bottom margin */
  border: 1px solid #00FF00;
}

.image-box img, 
.image-box video {
  width: 100%;                 /* Make sure the image takes up the full width of the container */
  height: auto;                /* Keep the aspect ratio */
  max-height: 100%;            /* Ensure it doesn't exceed the container's max-height */
  object-fit: cover;           /* Maintain aspect ratio and cover the container */
}

.project-link {
  color: #00FF00;                /* Set the link color to match the design */
  text-decoration: underline;    /* Underline to indicate it's a link */
  font-weight: bold;             /* Make the link text bold */
  position: relative;
  display: inline-flex;
  align-items: center;
}

.project-link:hover {
  color: #FFFF00;                /* Change the color on hover */
}

/* Add link icon styling */
.link-icon {
  margin-left: 5px;              /* Space between the text and the icon */
  font-size: 1.2rem;             /* Slightly larger icon */
  transition: transform 0.2s ease-in-out;
}

/* Rotate the icon slightly on hover */
.project-link:hover .link-icon {
  transform: rotate(20deg);
}
